<template>
  <div id="success">
    <success-component />
  </div>
</template>

<script>
import SuccessComponent from "@theme/components/appointment/success";

export default {
  name: "Success",
  components: {
    SuccessComponent,
  },
};
</script>
